<template>
<!--single root!-->
 <div style="padding-top:5%">
     
    <!-- <shortcut></shortcut> -->
        <!--============================= RESERVE A SEAT =============================-->
    <Carousel v-if="assetDetails.image" class="swiper">
        <CarouselItem v-for="(item,i) in assetDetails.image" :key="i">
          
            <img class="manImg" :src="key" v-for="(key,j) in item" :key="j"> 
        </CarouselItem>
    </Carousel> 

    <Carousel v-else class="swiper">
        <CarouselItem v-for="(item,i) in assetDetails.image" :key="i">
      
             <img class="manImg" src="@/assets/images/user.png" v-for="(key,j) in 3" :key="j">
        </CarouselItem>
    </Carousel> 
    <Carousel class="swiper" v-if="assetDetails.image.length == 0">
        <CarouselItem v-for="(item,i) in 1" :key="i">
            <img class="manImg" src="@/assets/images/user.png"  v-for="(key,j) in 3" :key="j">
            <!-- <img class="manImg" src="@/assets/images/user.png" v-for="(key,j) in 3" :key="j"> -->
        </CarouselItem>
    </Carousel> 






    <Carousel class="swiper2">
        <CarouselItem v-for="(item,i) in imgs" :key="i">
            <img class="manImg2" :src="item" >
            <!-- <img class="manImg" src="@/assets/images/user.png" v-for="(key,j) in 3" :key="j"> -->
        </CarouselItem>
    </Carousel> 
    <Carousel class="swiper2" v-if="imgs.length==0">
        <CarouselItem v-for="(item,i) in 1" :key="i">
            <img class="manImg2" src="@/assets/images/user.png" >
            <!-- <img class="manImg" src="@/assets/images/user.png" v-for="(key,j) in 3" :key="j"> -->
        </CarouselItem>
    </Carousel> 
    
    <br/>
    <!--//END BOOKING -->
    <!--============================= BOOKING DETAILS =============================-->
    <section style="background:#f2f2f2" class="light-bg booking-details_wrap">


        <div class="container" >
            <div class="row">
                <div class="col-md-8 responsive-wrap" >
                    <div class="booking-checkbox_wrap" style="border: 1px solid lightgray">
                        <div class="row iju">
                            <div class="col-md-8 swiperWrap">
                                
                                <div class="manInfo">
                                    <div class="al">
                                        <h5 style="color:darkblue">{{$i18n.locale=='zh-TW' ? assetDetails.name : assetDetails.name_en}} </h5>
                                        <span v-if="assetDetails.language" style="padding:5px 10px"> &nbsp; &nbsp;{{assetDetails.experience}} 歲</span>
                                    </div>
                                    
                                    
                                </div>
                                
                            </div>
                            <div class="col-md-4 wid50">
                                <div class="reserve-seat-block">
                                    <div class="reserve-rating">
                                        $<span>{{assetDetails.price}} / {{$t('perHours')}}</span> <span v-if="assetDetails.language"></span> <span v-else></span>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                        <br/>
                         <div v-if="assetDetails.language" class="row">
                         <div class="col-md-4">
                                            <label class="custom-checkbox">
                            <span class="ti-check-box"></span>
                            <span class="custom-control-description">語言能力: <b>{{assetDetails.language}}</b></span>
                            </label> </div>
                            <div class="col-md-4" >
                                <label class="custom-checkbox">
                                    <span class="ti-check-box"></span> <!--✓!-->
                                    <span class="custom-control-description">是否抽煙: <b>{{assetDetails.smoking}}</b></span>
                                </label> </div>
                                        <div class="col-md-4">
                                            <label class="custom-checkbox">
                                <span class="ti-check-box"></span>
                                <span class="custom-control-description">急救/防火訓練: <b>{{assetDetails.fire_aid_training}}</b> </span>
                                </label>
                                        </div>
                                        <div class="col-md-4">
                                            <label class="custom-checkbox">
                                <span class="ti-check-box"></span>
                                <span class="custom-control-description">雷達及電子海圖使用: <b>{{assetDetails.radar_training}}</b>  </span>
                            </label> </div>
                                        <div class="col-md-4">
                                            <label class="custom-checkbox">
                                <span class="ti-check-box"></span>
                                <span class="custom-control-description">遊樂設備操作: <b>{{assetDetails.game_operation}}</b></span>
                            </label>
                                        </div>
                                        <div class="col-md-4">
                                            <label class="custom-checkbox">
                            <span class="ti-check-box"></span>
                            <span class="custom-control-description">可超時: <b>{{assetDetails.otable}}</b></span>
                            
                            </label>
                            </div><br/>
                            <div class="col-md-8">
                                            <label class="custom-checkbox">
                            <span class="ti-check-box"></span>
                            <span class="custom-control-description">駕駛執照: {{assetDetails.license}}</span>
                            
                            </label>
                            </div>
                        </div>
                        <div class="reserve-description">{{  $i18n.locale=='zh-TW' ? assetDetails.description : assetDetails.description_en}}</div>
                    </div>
                    <br>
                    <div class="booking-checkbox_wrap" style="border: 1px solid lightgray;min-height: 316px;">
                        <h5>{{$t("bookInfo")}}</h5>
                        <br/>
                        <hr>
                        <div class="customer-review_wrap">
                            <div style="padding-top: 10px;">
                                <div class="width50">
                                    <div class="orderFormItem">
                                        <label class="orderLabel">{{$t('bookingDate')}} : </label>
                                        <input id="bookingDate"  :min="min" v-model="booking_date" type="date" placeholder=" 預訂日期" class="formInp" />
                                    </div>
                                    <br/>
                                    <div class="orderFormItem">
                                        <label class="orderLabel">{{$t('bookingTime')}} : </label>
                                        <!-- <input id="bookingDate" v-model="booking_Time" type="time" placeholder=" 預訂日期" class="formInp" /> -->
                                        <div class="formInp sb">
                                            <select style="width: 48%;" v-model="hours">
                                                <option v-for="(item,i) in hoursList" :key="i" :value="item">{{item}}</option>
                                            </select>
                                            <select style="width: 48%;" v-model="minute">
                                                <option value="00">00</option>
                                                <option value="30">30</option>
                                            </select>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="orderFormItem">
                                        <label class="orderLabel">{{$t('bookingperiod')}} : </label>
                                        <input id="booking_Period" v-model="booking_Period" type="number" min="2" :placeholder="$t('bookingperiod')" class="formInp"/>
                                    </div>
                                    <br>
                                    <div class="orderFormItem" v-if="service_id != '24'">
                                        <label>{{$t('TheEngineNumber')}} : </label>
                                        <input id="engine_qty" v-model="engine_qty" type="number" min="1" :placeholder="$t('engineNumber')" class="formInp"/>
                                    </div>
                                    <br v-if="service_id != '24'" />
                                    <div class="orderFormItem" v-if="service_id != '24'">
                                        <label class="orderLabel">{{$t('shippingName')}} : </label>
                                        <input v-model="yacht_name" type="text" :placeholder="$t('shippingName')" class="formInp"/>
                                    </div>
                                    <br v-if="service_id != '24'">
                                    <div class="orderFormItem" v-if="service_id != '24'">
                                        <label class="orderLabel">{{$t('shippingLength')}} ({{$t('no15')}}) : </label>
                                        <input v-model="length" type="number" min="1" max="15" :placeholder="$t('shippingLength')" class="formInp"/>
                                    </div>
                                    <br v-if="service_id != '24'">
                                    <div class="orderFormItem" >
                                        <label class="orderLabel">{{$t('email')}} : </label>
                                        <input v-model="email" type="text"  :placeholder="$t('email')" class="formInp"/>
                                    </div>
                                    <br>
                                    
                                </div>
                                <div class="width50">
                                    <div class="orderFormItem" v-if="!assetDetails.departLocation">
                                        <label class="orderLabel">{{$t('shippingLocation')}} : </label>
                                        <input id="department_place" v-model="address" type="text" :placeholder="$t('shippingLocation')" class="formInp"/>
                                    </div>
                                    <br v-if="!assetDetails.departLocation">
                                    <div class="orderFormItem">
                                        <label class="orderLabel">{{$t('phone')}} : </label>
                                        <div class="ju phoneWrap">
                                            <select v-model="pre" style="width: 30%;margin-right: 5px;">
                                                <option value="+852 ">852</option>
                                                <option value="+853 ">853</option>
                                                <option value="+86 ">86</option>
                                            </select>
                                           <input id="department_place" v-model="phone" type="number" :placeholder="$t('phone')" class="formInp"/> 
                                        </div>
                                        
                                    </div>
                                    <br>
                                    <div class="orderFormItem">
                                        <label class="orderLabel" style="vertical-align:top;">{{$t("remark")}} : </label>
                                        <textarea id="department_place" v-model="remark" type="text" rows="11" :placeholder="$t('specialRequirment')" class="formInp"></textarea>
                                    </div>
                                </div><br>
                                

                            </div>
                        </div>
                
                    </div>
                </div>
                <div class="col-md-4 responsive-wrap">
                    <div class="contact-info booking-checkbox_wrap" style="border: 1px solid lightgray">
                        <br/>
                        <h5>{{$t("orderDetail")}}</h5>
                        <br>
                        <hr>
                        <br>
                        <div class="address">
                            <span class="icon-screen-smartphone"></span>
                            <p>${{assetDetails.price}} / {{$t('perHours')}}</p>
                        </div>
                          <!--<div class="address">
                            <span class="icon-screen-smartphone"></span>
                            <p>{{$t('coaching')}}</p>
                        </div>!-->
                           <div class="address">
                            <span class="icon-screen-smartphone"></span>
                            <p>{{$t('shipProvide')}}</p>
                        </div>
                        <div class="address">
                            <span class="icon-link"></span>
                            <p>{{$t('needlunch')}}</p>
                        </div>
                        <div class="address" v-if="assetDetails.departLocation">
                            <span class="icon-link"></span>
                            <p>
                                <span> {{$i18n.locale=='zh-TW' ? "上船地點 : " + assetDetails.departLocation : "Departure : " + assetDetails.departLocation_en}}</span>
                            </p>
                        </div>
                        <div class="address">
                            <span class="icon-clock"></span>
                            <p>{{$t('SailingTime')}} 10:00am - 18:30pm </p>
                        </div>
                        <br>
                        <br>
                        <div class="textBox">
                            <div>
                               <b >{{$t("termtitle")}}</b><br/>
                                <ol><br/>
                                    <li>
                                        <b>{{$t("term1Title")}}</b><br/>
                                       {{$t("term1")}}<br/>
                                       {{$t("term1two")}}<br/>
                                       {{$t("term1three")}}<br/>
                                       {{$t("term1four")}}<br/>
                                       {{$t("term1five")}}
                                     </li><br/>
                                    <li> 
                                        <b>{{$t("term2Title")}}</b><br/>
                                        {{$t("term2")}} <br/>
                                        {{$t("term2two")}}<br/>
                                        {{$t("term2three")}}
                                    </li> <br/>
                                    <li>
                                         <b>{{$t("term3Title")}}</b><br/>
                                        {{$t("term3")}}
                                        {{$t("term3two")}}<br/>
                                        {{$t("term3three")}}
                                    </li><br/>
                                    <li>
                                        <b> {{$t("term4Title")}}</b><br/>
                                        {{$t("term4")}}
                                        {{$t("term4two")}}<br/>
                                       {{$t("term4three")}}<br/>
                                       {{$t("term4four")}}<br/>
                                       {{$t("term4five")}}
                                    </li> 
                                </ol>   
                            </div>
                        </div>
                        <div >
                            <label for="check" class="al checLabel">
                                <input type="checkbox" id="check" v-model="check">
                                <span class="smallText">{{$t("iAgree")}}</span>
                            </label>
                            <div class="line18"></div>
                        </div>
                        
                        <div class="sb pa2">
                            <h6>{{$t('total')}}</h6>
                            <h6>{{ booking_Period ? booking_Period * assetDetails.price : 0}} $</h6>
                        </div>

                        <div>
                            <a v-if="loading" class="btn btn-contact">{{$t("ordering")}}...</a>
                            <a v-else class="btn btn-contact" @click="sub">{{$t("placeAnOrder")}}</a>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- <transition> -->
        <div v-if="success" class="successModal">
            <div class="centerM" @click.stop="">
                <div >
                    <br>
                    <div class="ju"><img src="@/assets/images/logo.png" width="90" ></div>
                    <div class="ju" style="padding: 0.3rem 0;">{{$t("thankOrder")}}</div>
                    <div class="successMain">
                        <p>{{$t('pleasePay')}}</p>
                        <p> HK$ {{ assetDetails.price * 2}}</p>
                        <div class="ju">
                            <img src="@/assets/images/acc.png" style="width: 100%;">
                        </div>
                        <div class="ju twoNum" style="text-align:center;">
                            <p >143141562001</p>
                            <p >161224431</p>
                        </div>
                        <br>
                        
                        <p>{{$t("payMsg")}}</p>
                        <p>+852 6099 4051</p>
                        <!--whatsapp FPS hsbc!-->
                    </div> 
                    <div class="mar1">
                        <p>{{$t('orderNum')}}:</p>
                        <p>{{order_num}}</p> 
                    </div>
                    
                    <div class="ju orderbtnWrap">
                        <div class="op" @click="changePath('/')">{{$t("backToHome")}}</div>
                        <div class="op" @click="changePath('/userPanel')">{{$t("myOrders")}}</div>
                    </div>
                    <br>
                    <br>
                </div>
            </div>
        </div>
    <!-- </transition> -->
    

  </div>
</template>

<script>
// import shortcut from '@/components/shortcut';
// import PayPal from 'vue-paypal-checkout';
// import Swal from 'sweetalert2'
// import $ from 'jquery';
import { Carousel } from "view-design" 
import { CarouselItem } from "view-design"
export default {
    name: 'order',
    data () {
        return {
            msg: 'application',
            loading: false,
            assetDetails:{ image:[] },
            imgs: [],
            assetPrice: "",
            imageList1: "",
            imageList2: "",
            imageList3: "",
            credentials: {
                sandbox: 'ATCYQBFQLJOUHQPAi6x29j5gG0n4QFyUErkmajnkMlRe_aIXe-XHkiMusCU-hUCT2QUx9XcuK0mJsZqu',
                production: 'ATCYQBFQLJOUHQPAi6x29j5gG0n4QFyUErkmajnkMlRe_aIXe-XHkiMusCU-hUCT2QUx9XcuK0mJsZqu'
            },
            myStyle: {
                label: 'checkout',
                size:  'responsive',
                shape: 'pill',
                color: 'blue'
            },

            "engine_qty":"",
            "booking_date":"",
            booking_Time:"",
            booking_Period:"",
            "address":"",
            "service_id":"",
            yacht_name:"",
            length: "",
            success: false,
            order_num: "",
            phone:"",
            remark:"",
            check: false,
            hoursList:['10',"11","12","13","14","15","16","17",'18'],
            hours: "10",
            minute:"00",
            min:"",
            email: "",
            pre: "+852 ",
        }
    },
    components:{
        // 'shortcut':shortcut,
        Carousel,
        CarouselItem
        // PayPal
    },
    mounted() {
        let D = new Date( Date.now()+ (86400000*3) ) //只能夠選擇三天後
        let y = D.getFullYear()
        let m = D.getMonth() + 1
        m = m < 10 ? "0" + m : m
        let d = D.getDate()
        d = d < 10 ? "0" + d : d
        this.min = y + "-" + m + "-" + d 
        // for (let i=0;i<24;i++) {
        //     this.hoursList.push(i<10 ? "0" + i : i + "")
        // }

        this.service_id = this.$route.query.id
        this.$http.get("service?id=" + this.service_id).then(res => {
            console.log(res)
            if (res.data.code == 200) {
                this.assetDetails = res.data.data

                if (this.assetDetails.image) {
                    this.imgs = res.data.data.image
                    // this.assetDetails.image = this.assetDetails.image.split(";")
                    let arr = this.assetDetails.image.split(",")
                    let newArr = []
                    for(let i = 0; i< Math.ceil(arr.length/3); i++) {
                        newArr.push(arr.slice(i*3,i*3+3))

                    }
                    this.assetDetails.image = newArr
                }else {
                    this.assetDetails.image = []
                }
                if (this.assetDetails.departLocation) {
                    this.address = this.assetDetails.departLocation
                }
                
            } else {
                this.assetDetails = {}
            }
        }).catch((e) => {
            console.log(e)
        })
    },
    computed: {
        login () { return this.$store.state.login }
    },
    methods:{
        changePath (path) {
            this.$router.push(path)
        },
        sub () {
            // console.log(this.booking_Time)
            if (!this.login) {
                this.$router.push("/register")
                return false
            }
            
            if (!this.booking_date) {
                this.$Swal.fire({
                    icon:"warning",
                    text:this.$t("chooseBookingDate"),// "請選擇預定時間",
                    confirmButtonText: this.$t('confirm')
                })
            } 
            else if (!this.booking_Period) {
                this.$Swal.fire({
                    icon:"warning",
                    text: this.$t("twoHours"),// "每節不得少於2小時",
                    confirmButtonText: this.$t('confirm')
                })
            } 
            else if (this.service_id!=24 && (this.engine_qty === 0)) {
                this.$Swal.fire({
                    icon:"warning",
                    text: this.$t("engNo"),// "主機數量不得小於1",
                    confirmButtonText: this.$t('confirm')
                })
            }
            else if (this.service_id!=24 && (this.engine_qty === "0")) {
                this.$Swal.fire({
                    icon:"warning",
                    text: this.$t("engNo"),// "主機數量不得小於1",
                    confirmButtonText: this.$t('confirm')
                })
            }
            else if (this.service_id!=24 && (Number(this.engine_qty) < 1)) {
                this.$Swal.fire({
                    icon:"warning",
                    text: this.$t("engNo"),// "主機數量不得小於1",
                    confirmButtonText: this.$t('confirm')
                })
            }
            else if (this.service_id!=24 && !this.yacht_name) {
                this.$Swal.fire({
                    icon:"warning",
                    text: this.$t("shipId"),//"請輸入船隻編號",
                    confirmButtonText: this.$t('confirm')
                })
            }
            else if (this.service_id!=24 && !this.length) {
                this.$Swal.fire({
                    icon:"warning",
                    text: this.$t("noLength"),//"請輸入船隻長度",
                    confirmButtonText: this.$t('confirm')
                })
            }
            else if (this.service_id!=24 && Number(this.length) > 15 ) {
                this.$Swal.fire({
                    icon:"warning",
                    text: this.$t("no15m"),//"船隻長度不可超过15米",
                    confirmButtonText: this.$t('confirm')
                })
            }
            else if (!this.email) {
                this.$Swal.fire({
                    icon:"warning",
                    text: this.$t("noEmail"),//"請輸入email",
                    confirmButtonText: this.$t('confirm')
                })
            }
            else if (!this.email.includes("@")) {
                this.$Swal.fire({
                    icon:"warning",
                    text: this.$t("emailRe"),//"email格式有误",
                    confirmButtonText: this.$t('confirm')
                })
            }
            else if (!this.address && (!this.assetDetails.departLocation)) {
                this.$Swal.fire({
                    icon:"warning",
                    text: this.$t("upLoca"),//"請輸入上船地點",
                    confirmButtonText: this.$t('confirm')
                })
            } 
            else if (!this.phone) {
                this.$Swal.fire({
                    icon:"warning",
                    text: this.$t("enterPhone"),//"請輸入聯絡電話",
                    confirmButtonText:this.$t('confirm')
                })
            } 
            else if (!this.check) {
                this.$Swal.fire({
                    icon:"warning",
                    text: this.$t("agreeA"),//"請同意条款",
                    confirmButtonText: this.$t('confirm')
                })
            }
            else  {
                let data = {
                    "yacht_name":this.yacht_name ? this.yacht_name : "None",
                    "engine_qty":this.engine_qty ? Number(this.engine_qty) : 1,
                    "booking_date":this.booking_date + ' ' + this.hours + ":" + this.minute,
                    "address": this.address,
                    "booking_Period":this.booking_Period,
                    "service_id":this.service_id,
                    phone: this.phone,
                    remark: this.remark,
                    yacht_len: this.length,
                    type: 1,
                    email: this.email,
                }
                this.loading = true
                this.$http.post("order",data,{ aaa:123 }).then(res => {
                    console.log(res)
                    this.loading = false
                    if (res.data.code == 200) {
                        this.success = true
                        this.order_num = res.data.order_num
                    } else {
                        this.$Swal.fire({
                            icon:"warning",
                            text: res.data.message,
                            confirmButtonText: this.$t('confirm')
                        })
                    }
                }).catch(e => {
                    this.loading = false
                    console.log(e)
                    this.$Swal.fire({
                        icon:"error",
                        text: "error",
                        confirmButtonText: this.$t('confirm')
                    })
                })
            }
        },
        errorimageUrlAlt() {
            // event.target.src = "@/assets/images/customer-img2.jpg"
        },
        checkTime(){
            // let test = $("#bookingDate").val();
            // console.log(test)
        },
        paymentAuthorized: function (data) {
            console.log(data);
        },
      
    }
}

</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.line18 {
    width: 90%;
    margin: auto;
    border-top: solid #CCC 1px;
}
.pa2 {
    color:#10103d;
    padding: 20px 20px 5px;
}
.width50 {
    width: 50%;
    display: inline-block;
    vertical-align: top;
}
.light-bg{
    background:#7EB0CE;
}

.titlename{
    color:white!important;
}
.successModal {
        position: fixed;
        width: 100vw;
        height: 100vh;
        background: rgba(0,0,0,0.5);
        left: 0;
        top: 0;
        z-index:1000;
        animation: a 0.3s;
        .successMain {
            margin: 1em;
            padding: 1em;
            border: solid 1px #383991;
            width: 66%;
            margin: auto;
        }
        p {
            text-align: center;
        }
    }
    .mar1 {
        margin: 0.5em;
    }
    @keyframes a {
        from {
            opacity: 0
        }
        to{
            opacity:1
        }
    }
    .centerM {
        position:fixed;
        left: 50%;
        top: 50%;
        padding: 0.5rem 1rem;
        background: #FFF;
        width:40%;
        transform: translate(-50%,-50%);
        border-radius: 5px;
        >div {
            border: solid #10103d 2px;
        }
    }
.orderbtnWrap {
    >div {
        width: 30%;
        padding: 0.5rem 0;
        color: #FFF;
        text-align:center;
        cursor: pointer;
        user-select: none;
        &:hover {
            opacity: 0.9;
        }
    }
    >div:first-child {
        background: #131D72;
    }
    >div:last-child {
        margin-left: 5%;
        background:#4FA079;
    }
}
/*
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}*/
.textBox {
    width: 90%;
    margin: auto;
    height: 205px;
    padding: 10px;
    border: solid #CCC 1px;
    overflow: auto;
}
.formInp {
    width: 70%;
}
.btn {
    cursor: pointer;
    border-radius: 0 !important;
}
.btn:hover {
    color: #FFF !important;
}
.smallText {
    color: #333;
    padding-left: 10px;
}
.checLabel {
    padding-left: 20px;
    padding-top: 10px;
}
.swiper {
    width: 70vw;
    margin:0 auto 5%;
    // border: solid red 1px;
}
.manImg {
    width: 20vw;
    /*height: 20vw;*/
    border: solid #CCC 1px;
    box-sizing: border-box;
    /* margin-left: 2vw; */
}
.manImg:nth-of-type(2) {
    margin: 0 7%;
}
.manInfo {
    margin-left: 1vw;
    
}
.swiperWrap {
    display: flex;
    align-items: center;;
}
.swiper2 {
    display: none;
}
.col-md-12 {
    display: flex;
    label {
        // border: solid;
        width: 50%;
        text-align: right;
        height: 1em;
        padding-right: 5px;
    }
}
.orderLabel {
    display: block;
    margin-bottom: 0.3rem !important;;
}
.orderFormItem {
    box-sizing:border-box;
    padding-left: 20%;
}
</style>